import React from "react";
import { AuthenticatedFrame } from "../components/AuthenticatedFrame/AuthenticatedFrame";
import { DocumentationContent } from "../components/DocumentationContent/DocumentationContent";
import { DocumentationPagePreview } from "../components/DocumentationPagePreview/DocumentationPagePreview";
import { Footer } from "../components/Footer/Footer";
import { graphql } from "gatsby";
import { NavigationHeader } from "../components/NavigationHeader/NavigationHeader";
import { Root } from "../page-styles/markdown.styles";
import { SEO } from "../components/SEO/SEO";
import { useTranslation } from "react-i18next";
import { WithoutAuth } from "../components/Auth/WithoutAuth";

const Docs = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    const [t] = useTranslation();
    const title = t("documentation");
    const description = t("coderoneDocumentation");

    const posts = edges.map((edge) => {
        return <DocumentationPagePreview key={edge.node.id} {...edge.node}></DocumentationPagePreview>;
    });
    const Fallback = (
        <AuthenticatedFrame title={title}>
            <DocumentationContent posts={posts} />
        </AuthenticatedFrame>
    );
    return (
        <React.Fragment>
            <SEO title={title} description={description} />
            <WithoutAuth fallback={Fallback}>
                <NavigationHeader />
                <Root>
                    <DocumentationContent posts={posts} />
                </Root>
                <Footer />
            </WithoutAuth>
        </React.Fragment>
    );
};
export default Docs;

export const pageQuery = graphql`
    query {
        allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___order] }
            filter: { fileAbsolutePath: { regex: "/(src/source-filesystem/docs)/" } }
        ) {
            edges {
                node {
                    id
                    timeToRead
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        description
                        order
                    }
                }
            }
        }
    }
`;
